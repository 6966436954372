<template>
  <div
    class="table_pre_load"
  >
    <div class="row-preload" v-for="row in props.row" :key="row">
      <div class="box_preload"></div>
    </div>
  </div>
</template>

<script lang="ts" src="@/presentation/components/preloader/table-preload/TablePreload.ts">
</script>

<style lang="scss" src="@/presentation/components/preloader/table-preload/TablePreload.scss" scoped>
</style>
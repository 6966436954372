import { __assign } from "tslib";
import { reactive, defineComponent, onMounted } from "vue";
import routePath from "@/commons/RoutePath";
import DIContainer from "@/core/DIContainer";
import UserInteractor from "@/domain/usecases/UserInteractor";
import CommonService from "@/services/CommonService";
import TablePreload from "@/presentation/components/preloader/table-preload/TablePreload.vue";
var MatchingStatus;
(function (MatchingStatus) {
    MatchingStatus[MatchingStatus["PROCESSING"] = 1] = "PROCESSING";
    MatchingStatus[MatchingStatus["FINISHED"] = 2] = "FINISHED";
    MatchingStatus[MatchingStatus["MATCHING"] = 3] = "MATCHING";
    MatchingStatus[MatchingStatus["NOT_MATCHED"] = 9] = "NOT_MATCHED";
})(MatchingStatus || (MatchingStatus = {}));
export default defineComponent({
    name: "ListProjects",
    components: { TablePreload: TablePreload },
    setup: function () {
        var userInteractor = DIContainer.instance.get(UserInteractor);
        var state = reactive({
            listProjects: [],
            totalProjects: 0,
            projectPerPage: 10,
            page: 1,
            fieldSortName: "",
            fieldSortType: "",
            matchingStatus: "",
            keyword: "",
            loading: false,
            noData: false,
            droptool: null,
            preload: true
        });
        var id = null;
        function convertDatetime(date) {
            if (!date) {
                return "";
            }
            var d = new Date(date), month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear();
            if (month.length < 2) {
                month = '0' + month;
            }
            if (day.length < 2) {
                day = '0' + day;
            }
            return [year, month, day].join('/');
        }
        function getListProjects(action) {
            var defaultParam = {
                "filter[user]": id,
                "filter[applied]": 1,
                "keyword": state.keyword,
                "order[column]": state.fieldSortName,
                "order[type]": state.fieldSortType,
                "limit": state.projectPerPage,
                "page": state.page
            };
            if (action == "loadMore") {
                state.loading = true;
            }
            var assignedParam = __assign(__assign({}, defaultParam), { "filter[matching_status][0]": MatchingStatus.PROCESSING, "filter[matching_status][1]": MatchingStatus.FINISHED });
            // AIQ-377: アサインされたプロジェクトがあればそれのみを表示し、ない場合は募集中のプロジェクトを表示する
            return userInteractor.getListUserProject(assignedParam).then(function (result) {
                if (!result.data || result.total_count === 0) {
                    var matchingParam = __assign(__assign({}, defaultParam), { "filter[matching_status][0]": MatchingStatus.MATCHING });
                    return userInteractor.getListUserProject(matchingParam);
                }
                else {
                    return result;
                }
            }).then(function (result) {
                if (!result.data || result.total_count == 0) {
                    if (action == "onMounted") {
                        state.noData = true;
                        state.preload = false;
                    }
                    else {
                        state.noData = false;
                        state.listProjects = [];
                        if (action == "getNew") {
                            state.totalProjects = 0;
                        }
                    }
                    state.loading = false;
                    return;
                }
                result.data.forEach(function (project) {
                    if (project.working_period_start_at && project.working_period_end_at) {
                        project.working_period_convert = convertDatetime(project.working_period_start_at) + " ~ " + convertDatetime(project.working_period_end_at);
                    }
                    else if (project.working_period_start_at && !project.working_period_end_at) {
                        project.working_period_convert = convertDatetime(project.working_period_start_at);
                    }
                    else if (!project.working_period_start_at && project.working_period_end_at) {
                        project.working_period_convert = convertDatetime(project.working_period_end_at);
                    }
                    else {
                        project.working_period_convert = "";
                    }
                });
                if (action == "getNew" || action == "onMounted") {
                    state.listProjects = result.data;
                    state.preload = false;
                }
                else {
                    state.listProjects = state.listProjects.concat(result.data);
                }
                state.totalProjects = result.total_count;
                state.loading = false;
                state.noData = false;
            }).catch(function (error) {
                state.loading = false;
                if (action == "onMounted") {
                    state.preload = false;
                    state.noData = true;
                }
            });
        }
        function changeProjectPerPage(number) {
            state.projectPerPage = number;
            localStorage.setItem("projectPerPage", number);
            state.page = 1;
            getListProjects("getNew");
        }
        function loadMoreProjects() {
            state.page++;
            getListProjects("loadMore");
        }
        function sortTable(field) {
            state.page = 1;
            if (field === state.fieldSortName) {
                state.fieldSortType = state.fieldSortType === "desc" ? "asc" : "desc";
            }
            else {
                state.fieldSortType = "asc";
            }
            state.fieldSortName = field;
            getListProjects("getNew");
        }
        function showDropTool(id) {
            state.droptool = state.droptool != id ? id : null;
        }
        function hideDropTool() {
            state.droptool = null;
        }
        onMounted(function () {
            var user = CommonService.getCookie("user");
            var user_infor = user ? JSON.parse(decodeURIComponent(user)) : null;
            id = user_infor && user_infor['id'] ? user_infor['id'] : null;
            var projectPerPage = localStorage.getItem("projectPerPage");
            state.projectPerPage = projectPerPage ? parseInt(projectPerPage) : 10;
            getListProjects("onMounted");
        });
        return {
            state: state,
            routePath: routePath,
            showDropTool: showDropTool,
            hideDropTool: hideDropTool,
            changeProjectPerPage: changeProjectPerPage,
            loadMoreProjects: loadMoreProjects,
            sortTable: sortTable
        };
    }
});

import { defineComponent } from "vue";
export default defineComponent({
    name: "TablePreload",
    props: ["row"],
    setup: function (props) {
        return {
            props: props
        };
    }
});

<template>
  <div class="list_projects">
    <div class="list_projects__table">
      <div class="list_projects__table__filter">
        <div class="table-infor">
          {{ state.listProjects.length }}件表示 /
          {{ state.totalProjects }}件中　 表示件数：<a
            class="offset"
            :class="{ active: state.projectPerPage == 10 }"
            @click="changeProjectPerPage(10)"
            >10</a
          >｜<a
            class="offset"
            :class="{ active: state.projectPerPage == 50 }"
            @click="changeProjectPerPage(50)"
            >50</a
          >
        </div>
      </div>
      <div class="list_projects__table__detail">
        <table>
          <thead>
            <tr>
              <th>
                <a
                  :class="{
                    active:
                      state.fieldSortName == 'title' &&
                      state.fieldSortType == 'asc',
                  }"
                  @click="sortTable('title')"
                  >プロジェクト名</a
                >
              </th>
              <th>
                <a
                  :class="{
                    active:
                      state.fieldSortName == 'matching_status' &&
                      state.fieldSortType == 'asc',
                  }"
                  @click="sortTable('matching_status')"
                  >プロジェクトステータス</a
                >
              </th>
              <th>
                <a
                    :class="{
                    active:
                      state.fieldSortName == 'category_name' &&
                      state.fieldSortType == 'asc',
                  }"
                    @click="sortTable('category_name')"
                >相談パターン</a
                >
              </th>
              <th v-if="false">
                <a class="dots"></a>
              </th>
            </tr>
          </thead>
          <tbody v-if="state.listProjects.length > 0">
            <tr
              v-for="project in state.listProjects"
              v-bind:key="project.project_id"
              :class="{ 'not-matching': project.matching_status === 9 }"
            >
              <td>
                <router-link :to="{ path: routePath.PROJECT_DETAIL + project.project_id }">{{
                  project.title
                }}</router-link>
              </td>
              <td :class="{ working: project.matching_status === 1 }">
                {{ project.matching_status_name || "" }}
              </td>
              <td>
                {{ project.category_name || "" }}
              </td>
              <td class="tools" v-if="false">
                <a class="dots" @click.stop="showDropTool(project.id)"></a>
                <div
                  class="drop-tools"
                  v-if="state.droptool == project.id"
                  v-click-outside="hideDropTool"
                >
                  <a>内容の編集</a>
                  <a>コピーして作成</a>
                  <a>非表示にする</a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <button
          v-show="state.listProjects.length < state.totalProjects"
          class="btn-s btn-next-light-blue shadow btn-load-more"
          @click="loadMoreProjects"
          :disabled="state.loading"
        >
          <i v-if="!state.preload && state.loading" class="fas fa-circle-notch fa-spin"></i>
          続きを見る
        </button>
      </div>
      <div class="list_projects__table__no_data" v-if="!state.preload && (state.noData || state.listProjects.length == 0)">
        {{state.noData ? "プロジェクトがまだありません" : "検索結果のデータがありません。"}}
      </div>
       <TablePreload v-if="state.preload" :row="10"/>
    </div>
  </div>
</template>

<script lang="ts" src="@/presentation/views/user/dashboard/list-projects/ListProjects.ts" scoped>
</script>

<style lang="scss" src="@/presentation/views/user/dashboard/list-projects/ListProjects.scss" scoped>
</style>
